import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/calendar"

function getSeatingChartLayout(school_id, params) {
  return Client.get(`${resource}/schools/${school_id}/searting-chart/`, { params })
}
function getSeatingChartByRoom(school_id, params) {
  return Client.get(`${resource}/schools/${school_id}/searting-chart/group-by-room/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}

function getStudentScheduleFree(school_id, schedule_id, params) {
  return Client.get(
    `${resource}/schools/${school_id}/schedules/${schedule_id}/student-seat-free/`,
    { params }
  )
}

function getTeacherScheduleFree(school_id, schedule_id, params) {
  return Client.get(
    `${resource}/schools/${school_id}/schedules/${schedule_id}/teacher-seat-free/`,
    { params }
  )
}

function upsertStudentSchedule(schedule_id, seat_id, data) {
  return Client.put(`${resource}/student-schedule/schedules/${schedule_id}/seats/${seat_id}/`, data)
}
function upsertTeacherSchedule(schedule_id, seat_id, data) {
  return Client.put(`${resource}/teacher-schedule/schedules/${schedule_id}/seats/${seat_id}/`, data)
}

function getAllScheduleSeatStatus(school_id, params) {
  return Client.get(`${resource}/schools/${school_id}/schedules/seat-status/all/`, { params })
}
function getDetailStudentSeat(schedule_id, student_seat_id, params) {
  return Client.get(
    `${resource}/student-schedule/schedules/${schedule_id}/seats/${student_seat_id}/`,
    {
      params
    }
  )
}
function getDetailTeacherSeat(schedule_id, teacher_seat_id, params) {
  return Client.get(
    `${resource}/teacher-schedule/schedules/${schedule_id}/seats/${teacher_seat_id}/`,
    {
      params
    }
  )
}

function getAllTeacherShiftRequest(teacher_shift_request_report_id, params) {
  return Client.get(
    `${resource}/teacher-shift-request-reports/${teacher_shift_request_report_id}/`,
    { params }
  )
}
function getAllClassInfo(teacher_id, params) {
  return Client.get(`${version}/tenant/teacher/${teacher_id}/class-information/`, { params })
}
function getTeacherReposrt(params) {
  return Client.get(`${resource}/teacher-shift-request-reports/`, { params })
}
function getTeacherCountLesson(teacher_id, params) {
  return Client.get(`${resource}/teachers/${teacher_id}/count-by-lesson/`, { params })
}
function upsertTeacherShift(data) {
  return Client.post(`${resource}/teacher-shift-request-reports/upsert/`, data)
}
function swapTeacherShift(data) {
  return Client.post(`${resource}/teacher-schedule/switch/`, data)
}
function swapStudentShift(data) {
  return Client.post(`${resource}/student-schedule/switch/`, data)
}
const api = {
  getSeatingChartLayout,
  getSeatingChartByRoom,
  get,
  create,
  update,
  remove,
  getStudentScheduleFree,
  getTeacherScheduleFree,
  upsertStudentSchedule,
  upsertTeacherSchedule,
  getAllScheduleSeatStatus,
  getDetailStudentSeat,
  getDetailTeacherSeat,
  getAllTeacherShiftRequest,
  getAllClassInfo,
  getTeacherReposrt,
  getTeacherCountLesson,
  upsertTeacherShift,
  swapTeacherShift,
  swapStudentShift
}
export default api
